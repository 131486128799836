import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../Icon';

const curls = css`
  content: '';
  width: calc(10% + 1px);
  height: 12px;
  position: absolute;
  display: block;
`;

// _1uS5y
export const CurlyBraceContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  position: relative;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
`;

// ERNBp
const LeftArm = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  border-top: 2px solid #0a0a0b;
  margin: 0 10%;
  width: 30%;
  &:before {
    ${curls}
    border-top: 2px solid #0a0a0b;
    border-left: 2px solid #0a0a0b;
    border-top-left-radius: 8px;
    margin-left: -10%;
    margin-top: -2px;
  }
  &:after {
    ${curls}
    border-bottom: 2px solid #0a0a0b;
    border-right: 2px solid #0a0a0b;
    border-bottom-right-radius: 6px;
    margin-left: 30%;
    margin-top: -12px;
  }
`;

// _2hC5Q
const RightArm = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  border-top: 2px solid #0a0a0b;
  margin: 0 10%;
  width: 30%;
  &:before {
    ${curls}
    border-bottom: 2px solid #0a0a0b;
    border-bottom-left-radius: 6px;
    margin-left: calc(-10% - 1px);
    margin-top: -12px;
    border-left: 2px solid #0a0a0b;
  }
  &:after {
    ${curls}
    border-top: 2px solid #0a0a0b;
    border-top-right-radius: 8px;
    margin-left: 30%;
    border-right: 2px solid #0a0a0b;
    margin-top: -2px;
  }
`;

// _1_D3c
const Options = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #0a0a0b;
  line-height: 25px;
  display: flex;
`;

// _2tXNi _3jmf5
const Detail = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  font-size: 12px;
  font-weight: 400;
  color: #0a0a0b;
  line-height: 25px;
  box-sizing: inherit;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 14px;
  display: block;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// _1Hw-X
const Collapse = styled.button`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  font-size: 12px;
  font-weight: 400;
  color: #0a0a0b;
  line-height: 25px;
  box-sizing: inherit;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  img {
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
`;

export const CurlyBrace = ({ detail }) => [
  <CurlyBraceContainer key="a">
    <LeftArm />
    <RightArm />
  </CurlyBraceContainer>,
  <Options key="b">
    <Detail>{detail}</Detail>
    <Collapse>
      <span>Collapse</span>
      <Icon width="9" height="6" name="carat-up" />
    </Collapse>
  </Options>,
];
