import { useEffect, useState } from 'react';

export function useOrgChart(lIID) {
  const [isFetching, setIsFetching] = useState(true);
  const [orgChart, setOrgChart] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
          const url = `${process.env.REACT_APP_API_URL}/org/${lIID}`;
          console.log('fetching from', url)
        const resp = await fetch(url);
        const o = await resp.json();
        // const o = {
        //   name: 'Linde',
        //   imgHref: 'https://picsum.photos/200',
        //   people: [
        //     {
        //       id: 'a',
        //       name: 'a',
        //       seniority: 'partner_cxo',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'a',
        //       name: 'a',
        //       seniority: 'partner_cxo',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'a',
        //       name: 'a',
        //       seniority: 'partner_cxo',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'a',
        //       name: 'a',
        //       seniority: 'partner_cxo',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'a',
        //       name: 'a',
        //       seniority: 'partner_cxo',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'b',
        //       name: 'b',
        //       seniority: 'vp_director',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'b',
        //       name: 'b',
        //       seniority: 'vp_director',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'b',
        //       name: 'b',
        //       seniority: 'vp_director',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'b',
        //       name: 'b',
        //       seniority: 'vp_director',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'b',
        //       name: 'b',
        //       seniority: 'vp_director',
        //       jobFunction: 'sales',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'c',
        //       name: 'c',
        //       seniority: 'manager',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'c',
        //       name: 'c',
        //       seniority: 'manager',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'c',
        //       name: 'c',
        //       seniority: 'manager',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'c',
        //       name: 'c',
        //       seniority: 'manager',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'c',
        //       name: 'c',
        //       seniority: 'manager',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //     {
        //       id: 'd',
        //       name: 'd',
        //       seniority: 'lower',
        //       jobFunction: 'marketing',
        //       imgHref: 'https://picsum.photos/396',
        //       title: 'CXO',
        //       description: 'jaslkdfjalksdjflkasjdfklasjlkdfjaslkdfjaskljdfalksjdflkas',
        //       profileLink: 'https://linkedin.com/in/aakarim',
        //     },
        //   ],
        // };
        setOrgChart(o);
      } catch (e) {
        setError(e);
      } finally {
        setIsFetching(false);
      }
    }
    setIsFetching(true);
    fetchData();
  }, [lIID]);
  return {
    isFetching,
    orgChart,
    error,
  };
}
