import React, { useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useOrgChart } from '../../hooks/useOrgChart';
import { CurlyBrace } from './CurlyBrace';
import SeniorityLevel from './SeniorityLevel';
import { Search } from './Search';
import {
  OrgChartBody,
  OrgChartBodyInner,
  OrgChartContainer,
  OrgChartHeader,
  OrgChartSubset,
  OrgChartSeparator,
  Tools,
  ButtonText,
  ListButton,
  CompanyNavRail,
  CompanyNavBrand,
  CompanyNavOverview,
  PageContentContainer,
  CompanyNavHeader,
  CompanyName,
  CompanyDetail,
} from './styles';

const splitSeniorityLevels = orgChart => ({
  partner_cxo: orgChart.people.filter(p => p.seniority === 'partner_cxo'),
  vp_director: orgChart.people.filter(p => p.seniority === 'vp_director'),
  manager: orgChart.people.filter(p => p.seniority === 'manager'),
  lower: orgChart.people.filter(p => p.seniority === 'lower'),
});

export const OrgChart = ({ lIId, orgChart }) => {
  const spl = splitSeniorityLevels(orgChart);
  const [searchQ, setSearchQ] = useState('');
  const [functionHighlight, setFunctionHighlight] = useState(false);
  return (
    <OrgChartContainer>
      <OrgChartHeader>
        <h2>Org Chart</h2>
        <Tools>
          <ListButton>
            <ButtonText href="list">View as list</ButtonText>
          </ListButton>
          <ListButton style={{alignItems: 'center'}}>
            <input
              value={functionHighlight}
              onChange={e => setFunctionHighlight(e.currentTarget.checked)}
              type="checkbox"
            ></input>
            Job Function
          </ListButton>
          <Search q={searchQ} onChange={e => setSearchQ(e.currentTarget.value)} />
          <ListButton style={{ marginLeft: '5px' }}>
            <ButtonText href="list">Export</ButtonText>
          </ListButton>
        </Tools>
      </OrgChartHeader>
      <OrgChartBody>
        <OrgChartBodyInner>
          <OrgChartSubset>
            <CompanyNavOverview>
              <img
                alt={`${orgChart.name} logo`}
                src={
                  orgChart.imgHref ?? 'https://static-exp1.licdn.com/sc/h/7kn6tg2e0ek9dhugj8l8t4dgg'
                }
                style={{ backgroundColor: '#b3b6b9' }}
              />
              <CompanyName>
                <CompanyDetail></CompanyDetail>
              </CompanyName>
            </CompanyNavOverview>
            <CurlyBrace detail="Partner/CXO" />
            <SeniorityLevel functionHighlight={functionHighlight} searchQ={searchQ} people={spl['partner_cxo']} />
            <OrgChartSubset>
              <CurlyBrace detail="VP/Director" />
              <SeniorityLevel functionHighlight={functionHighlight} searchQ={searchQ} people={spl['vp_director']} />
              <CurlyBrace detail="Manager" />
              <SeniorityLevel functionHighlight={functionHighlight} searchQ={searchQ} people={spl['manager']} />
              <CurlyBrace detail="Other" />
              <SeniorityLevel functionHighlight={functionHighlight} searchQ={searchQ} people={spl['lower']} />
            </OrgChartSubset>
          </OrgChartSubset>
        </OrgChartBodyInner>
      </OrgChartBody>
      <OrgChartSeparator>
        <hr />
      </OrgChartSeparator>
    </OrgChartContainer>
  );
};

export const OrgList = () => {
  return <div>LIST</div>;
};

export const Organisation = () => {
  const { lIId } = useParams();
  const match = useRouteMatch();
  const { isFetching, orgChart, error } = useOrgChart(lIId);
  if (isFetching) {
    return <div>LOADING</div>;
  }
  if (error) {
    return <div>Error: {error.toString()}</div>;
  }
  return (
    <PageContentContainer>
      <CompanyNavHeader>
        <CompanyNavRail>
          <CompanyNavBrand>
            <span>
              <CompanyNavOverview>
                <img
                  alt={`${orgChart.name} logo`}
                  src={
                    orgChart.imgHref ??
                    'https://static-exp1.licdn.com/sc/h/7kn6tg2e0ek9dhugj8l8t4dgg'
                  }
                  style={{ backgroundColor: '#b3b6b9' }}
                />
                <CompanyName>
                  <CompanyDetail>{orgChart.name}</CompanyDetail>
                </CompanyName>
              </CompanyNavOverview>
            </span>
          </CompanyNavBrand>
        </CompanyNavRail>
      </CompanyNavHeader>
      <OrgChartSeparator>
        <hr width="100%" />
      </OrgChartSeparator>
      <Switch>
        <Route path={`${match.path}/chart`}>
          <OrgChart lIId={lIId} orgChart={orgChart} />
        </Route>
        <Route path={`${match.path}/list`}>
          <OrgList orgChart={orgChart} />
        </Route>
        <Route path={`${match.path}`}>
          <OrgChart lIId={lIId} orgChart={orgChart} />
        </Route>
      </Switch>
    </PageContentContainer>
  );
};
