import styled from 'styled-components';

// _1jJN7
export const PageContentContainer = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  background-color: #fff;
`;

// _2Arlv
export const SeniorityContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  box-sizing: inherit;
  display: flex;
  -webkit-box-direction: normal;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  flex-flow: row wrap;
`;

export const PaginationContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  box-sizing: inherit;
  display: flex;
  -webkit-box-direction: normal;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  flex-flow: row wrap;
`;

// _2gDjU
export const OrgChartContainer = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  padding: 56px 0;
`;

// _2YTGL
export const OrgChartHeader = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
`;

// _3DJOM
export const OrgChartBody = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  width: 100%;
  margin: 0 auto;
`;

// _1gMpC
export const OrgChartBodyInner = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin: 0 auto 20px;
  font-size: 16px;
  line-height: normal;
`;

// NXkpA
export const OrgChartSubset = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  box-sizing: inherit;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  -webkit-box-direction: normal;
  width: 100%;
`;


// _3h_GZ
export const OrgChartSeparator = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin: 40px auto;
  hr {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    color: #0a0a0b;
    font-size: 16px;
    font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --spacing-body: 40px 48px;
    -webkit-tap-highlight-color: transparent;
    box-sizing: content-box;
    overflow: visible;
    display: block;
    height: 1px;
    border: 0;
    padding: 0;
    margin: 40px auto;
    border-top: 2px solid #eaeaeb;
  }
`;

// _1FXg7
export const Tools = styled.span`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  display: flex;
`;

// rOmLI
export const ListButton = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
`;

// Re3eS
export const SearchButton = styled.div`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  text-transform: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  color: #1e2ff1;
  text-decoration: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  overflow: visible;
  outline: 0;
  cursor: pointer;
  border: 1px solid #eaeaeb;
  box-sizing: border-box;
  height: 32px;
  min-width: 0;
  font-size: 14px;
  line-height: 20px;
  padding-left: 14px;
  padding-right: 14px;
  margin-left: 10px;
  border-radius: 100px;
  svg {
    margin-top: 8px;
  }
  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #eaeaeb;
    color: #000;
    cursor: pointer;
  }
`;

export const SearchField = styled.input`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  text-transform: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  color: #1e2ff1;
  text-decoration: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  overflow: visible;
  outline: 0;
  border: 1px solid #eaeaeb;
  box-sizing: border-box;
  height: 32px;
  min-width: 100px;
  font-size: 14px;
  line-height: 20px;
  padding-left: 14px;
  padding-right: 14px;
  margin-left: 10px;
  border-radius: 100px;
  svg {
    margin-top: 8px;
  }
  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #eaeaeb;
    color: #000;
    cursor: pointer;
  }
`;

// _2zFQ9 _1CncX _1Rnaz
export const ButtonText = styled.a`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  margin: 0;
  background: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 25px;
  transition: all 0.1s ease;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  min-width: 0;
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  padding-left: 14px;
  padding-right: 14px;
  color: #0a0a0b;
  border: 1px solid #eaeaeb;
  background-color: #fff;
  border-radius: 100px;
  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #eaeaeb;
    color: #0a0a0b;
    cursor: pointer;
  }
`; 

// _12an0
export const CompanyNavHeader = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  z-index: 26;
  background-color: #fff;
  position: relative;
`;

// CRrDa
export const CompanyNavRail = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  margin: 0 auto;
  padding: 56px 45px 40px;
  max-width: 1024px;
  box-sizing: content-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

// KvIby
export const CompanyNavBrand = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  max-width: calc(100% - 205px);
  -webkit-box-flex: 1;
  flex: 1 1;
`;

// KpuKT _2xsV1 _8Y4T_
export const CompanyNavOverview = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #0a0a0b;
  -webkit-box-flex: 1;
  flex: 1 1;
  img {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border: 1px solid #eaeaeb;
    width: 56px;
    height: 56px;
    border-radius: 14px;
    background-color: #fff;
  }
`;

// _2aDbX
export const CompanyName = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  color: #0a0a0b;
  box-sizing: inherit;
  min-width: 0;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 0;
  -webkit-box-pack: center;
  justify-content: center;
  margin-left: 8px;
  -webkit-box-flex: 1;
  flex: 1 1;
`;

// _3byAW
export const CompanyDetail = styled.div`
  h1 {
    -webkit-text-size-adjust: 100%;
    font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --spacing-body: 40px 48px;
    -webkit-tap-highlight-color: transparent;
    color: #0a0a0b;
    -webkit-box-direction: normal;
    box-sizing: inherit;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 8px;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
  }
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  color: #0a0a0b;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  margin-top: -3px;
`;

export const PaginationButton = styled.button`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  line-height: 1.15;
  text-transform: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border-width: 0;
  background: none;
  color: #fff;
  text-decoration: none;
  font-size: inherit;
  outline: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  overflow: visible;
  width: fit-content;
`;

export const PaginationButtonText = styled.div`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  line-height: 1.15;
  text-transform: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  box-sizing: inherit;
  padding: 0 8px 1px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 24px;
  font-size: 12px;
  background-color: #fff;
  border-radius: 3px;
  font-weight: 400;
  color: #0a0a0b;
  z-index: 5;
  transition: background-color 0.3s ease;
  text-transform: none;
  appearance: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  outline: 0;
  cursor: pointer;
  border: 1px solid #eaeaeb;
  height: 32px;
  min-width: 0;
  line-height: 20px;
  padding-left: 14px;
  padding-right: 14px;
  margin-left: 10px;
  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #eaeaeb;
    color: #000;
    cursor: pointer;
  }
  :disabled {
    color: #eaeaeb;
  }
  
`;
