import React, { useState } from 'react';
import { Card } from './Card';
import {
  SeniorityContainer,
  PaginationContainer,
  PaginationButton,
  PaginationButtonText,
} from './styles';

const SeniorityLevel = ({ people, searchQ, functionHighlight }) => {
  const perPage = 10;
  const sorted = people.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });
  let filtered = sorted;
  const dsl = searchQ.split('function:');
  console.log(dsl)
  if (dsl.length > 1) {
    filtered = dsl[1] === '' ? filtered : filtered.filter(p => p.jobFunction?.includes(dsl[1]))
  }
  filtered = dsl[0] === '' ? filtered : filtered.filter(p => p.title?.toLowerCase().includes(dsl[0].trim()));
  const [pageIndex, setPageIndex] = useState(0);
  const numPages = Math.ceil(filtered.length / perPage);
  const startingIndex = perPage * pageIndex;
  const endingIndex = startingIndex + perPage;
  const paginated = filtered.slice(startingIndex, endingIndex);
  return [
    <SeniorityContainer key="a">
      {paginated.map(p => (
        <Card functionHighlight={functionHighlight} key={`person:${p.id}`} person={p} />
      ))}
    </SeniorityContainer>,
    <Pagination
      key="b"
      numPages={numPages}
      currentPage={pageIndex}
      onPageClick={i => setPageIndex(i)}
    />,
  ];
};

const Pagination = ({ numPages, currentPage, onPageClick }) => {
  if (numPages < 2) {
    return <div></div>;
  }
  let els = [...Array(numPages).keys()].map(p => (
    <PaginationButton onClick={() => onPageClick(p)} disabled={p === currentPage} key={p}>
      <PaginationButtonText>{p + 1}</PaginationButtonText>
    </PaginationButton>
  ));
  let truncated = false;
  if (numPages > 10) {
    els = els.slice(currentPage, currentPage + 10);
    truncated = true;
  }
  return (
    <PaginationContainer>
      <PaginationButton onClick={() => onPageClick(currentPage - 1)} disabled={currentPage === 0}>
        <PaginationButtonText>Previous</PaginationButtonText>
      </PaginationButton>
      {truncated && currentPage > 0
        ? [
            <PaginationButton onClick={() => onPageClick(0)} disabled={0 === currentPage} key={0}>
              <PaginationButtonText>{1}</PaginationButtonText>
            </PaginationButton>,
            '...',
          ]
        : null}
      {els}
      {truncated
        ? [
            '...',
            <PaginationButton
              onClick={() => onPageClick(numPages - 1)}
              disabled={numPages - 1 === currentPage}
              key={numPages - 1}
            >
              <PaginationButtonText>{numPages}</PaginationButtonText>
            </PaginationButton>,
          ]
        : null}
      <PaginationButton
        onClick={() => onPageClick(currentPage + 1)}
        disabled={currentPage === numPages - 1}
      >
        <PaginationButtonText>Next</PaginationButtonText>
      </PaginationButton>
    </PaginationContainer>
  );
};

export default SeniorityLevel;
