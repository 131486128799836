import React from 'react';
import Icon from '../Icon';
import { SearchButton, SearchField } from './styles';

export const Search = ({ q, onChange, onClick }) => {
  return [
    <SearchField key="a" onChange={onChange} value={q} />,
    <SearchButton key="b" onClick={onClick}>
      <Icon height="14" width="14" fill="none" name="search" />
    </SearchButton>,
  ];
};
