import React from 'react';
import Icon from '../../Icon';
import {
  Box,
  CardContainer,
  Collapse,
  CollapseText,
  Ellipsis,
  InnerContainer,
  MarginCard,
  Name,
  Options,
  Picture,
  PictureLink,
  Position,
} from './styles';

export const functionName = functionKey => {
  switch (functionKey) {
    case 'leadership':
        return 'Leadership';
    case 'sales':
      return 'Sales';
    case 'finance':
      return 'Finance';
    case 'engineering_technical_scientific':
      return 'Engineering / Technical / Scientific';
    case 'operations':
      return 'Operations';
    case 'marketing_communications':
      return 'Marketing / communications';
    case 'legal_compliance':
      return 'Legal / Compliance';
    case 'strategic_planning_business_development':
      return 'Strategic planning / business development';
    case 'human_resources':
      return 'Human resources';
    case 'customer_service_support':
      return 'Customer service / Support';
    case 'purchasing_and_procurement':
      return 'Purchasing and procurement';
    case 'program_and_project_management':
      return 'Program and project management';
    case 'other':
      return 'Other';
    case 'unknown':
      return 'Unknown';
    default:
      return 'Unkown';
  }
};


export const Card = ({ person, functionHighlight }) => {
  const profileLink = `https://www.linkedin.com/sales/people/${person.salesNavId},name`;
  return (
    <CardContainer style={{ zIndex: 'initial' }}>
      <InnerContainer>
        <MarginCard onClick={() => window.open(profileLink)}>
          <PictureLink>
            <a
              target="_blank"
              rel="noreferrer"
              href={profileLink}
              title={`view ${person.name} on LinkedIn`}
            >
              <Picture>
                <img
                  alt={person.name}
                  style={{ height: '100%', width: '100%' }}
                  src={
                    person.imgHref ?? 'https://static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                  }
                ></img>
              </Picture>
            </a>
          </PictureLink>
          <Box style={{
            height: functionHighlight ? '124px' : null
          }} role="button" tabindex="-1">
            <Name>{person.name}</Name>
            <Position>{person.title}</Position>
            {functionHighlight ? (
              <Position style={{color: "#7127F6"}}>{functionName(person.jobFunction)}</Position>
            ) : null}
          </Box>
          <Collapse>
            <CollapseText>
              <Icon width="9" height="6" name="carat-up" />
            </CollapseText>
          </Collapse>
        </MarginCard>
        <div>
          <Options>
            <Ellipsis>
              <span></span>
              <span></span>
              <span></span>
            </Ellipsis>
          </Options>
        </div>
      </InnerContainer>
    </CardContainer>
  );
};
