import caratUp from './carat-up.svg';
import caratDown from './carat-down.svg';
import { ReactComponent as Search } from './search.svg';

const Icon = (props) => {
    const { name, width, height } = props;
  switch (name) {
    case 'carat-up':
      return <img width={width} height={height} src={caratUp} alt="" />;
    case 'carat-down':
      return <img width={width} height={height} src={caratDown} alt="" />;
    case 'search':
        return <Search {...props}/> 
    default:
      throw new Error('specify an icon');
  }
};

export default Icon;
