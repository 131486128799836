import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Organisation } from './components/OrgChart';
import logo from './lix-logo.svg';
import { AppContainer, ContentContainer, Header, Logo } from './styles';

function App() {
  return (
    <Router>
      <Header>
          <h1>lookC</h1>
          <Logo src={logo} />
      </Header>
      <AppContainer>
        <ContentContainer>
          <Switch>
            <Route path="/org/:lIId">
              <Organisation />
            </Route>
          </Switch>
        </ContentContainer>
      </AppContainer>
    </Router>
  );
}

export default App;
