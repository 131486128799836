import styled from 'styled-components';

// _37ODz
export const CardContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  margin: 0 9px 33px;
  z-index: initial;
`;

// _17S4V _2B3Ql
export const InnerContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  transition: opacity 0.3s ease;
  position: relative;
  width: 100%;
  outline: none;
  text-align: center;
  cursor: pointer;
`;

// _1lrdz
export const MarginCard = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  cursor: pointer;
  box-sizing: inherit;
  position: relative;
  width: 100%;
  outline: none;
  text-align: center;
  transition: opacity 0.2s;
  margin-top: 20px;
`;

// gdGft
export const PictureLink = styled.div`
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  -webkit-box-direction: normal;
  cursor: pointer;
  text-align: center;
  box-sizing: inherit;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: -6px;
  z-index: 5;
`;

// _3tWtQ Eq1jw
export const Picture = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  line-height: normal;
  -webkit-box-direction: normal;
  text-align: center;
  color: #1e2ff1;
  font-size: inherit;
  box-sizing: inherit;
  display: block;
  border-radius: 100%;
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  overflow: hidden;
`;

// _16Lj2
export const Box = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
  text-align: center;
  box-sizing: inherit;
  background-color: #fff;
  width: 219px;
  height: 87px;
  border-radius: 12px;
  color: #0a0a0b;
  outline: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px #eaeaeb;
  transition: box-shadow 0.2s, border 0.3s ease, -webkit-box-shadow 0.2s;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;

// _1vJJ2
export const Collapse = styled.button`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  line-height: 1.15;
  text-transform: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border-width: 0;
  background: none;
  color: #1e2ff1;
  text-decoration: none;
  font-size: inherit;
  outline: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  overflow: visible;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
`;

// _1EZOU
export const CollapseText = styled.div`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  line-height: 1.15;
  text-transform: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  box-sizing: inherit;
  padding: 0 8px 1px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 24px;
  font-size: 12px;
  background-color: #7127f6;
  border-radius: 20px;
  font-weight: 400;
  color: #fff;
  z-index: 5;
  transition: background-color 0.3s ease;
`;

// _26nIL
export const Name = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  text-align: center;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  outline: none;
  font-size: 14px;
  margin-bottom: 1px;
  padding: 0 10px;
  line-height: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #0a0a0b !important;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

// _1xSyr
export const Position = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  text-align: center;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
  padding: 0 10px;
  color: #77777c;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

// _3XhtP _1I4ky
export const Options = styled.button`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  line-height: 1.15;
  text-transform: none;
  appearance: none;
  margin: 0;
  border-width: 0;
  background: none;
  color: #1e2ff1;
  text-decoration: none;
  font-size: inherit;
  outline: none;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  overflow: visible;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
`;

// _2TODD
export const Ellipsis = styled.div`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  line-height: 1.15;
  text-transform: none;
  color: #1e2ff1;
  font-size: inherit;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  box-sizing: inherit;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 29px;
  height: 19px;
  border-radius: 20px;
  border: 1px solid transparent;
  transition: border 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  outline: none;
  span {
    -webkit-transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    display: block;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    margin: 0 1px;
    background-color: #b1b1b4;
  }
`;