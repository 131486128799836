import styled from 'styled-components';

// _2Wioi
export const AppContainer = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
`;
export const Logo = styled.img`
    height: 50px;
`;

// dQ5WG
export const ContentContainer = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: #0a0a0b;
  font-size: 16px;
  font-family: Basis Grotesque Pro, HelveticaNeue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  --spacing-body: 40px 48px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
  padding-left: 45px;
  padding-right: 45px;
  max-width: 1114px;
`;

export const Header = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  height: 64px;
  padding: 0 45px;
  max-width: 1024px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  border-bottom: 1px solid #eaeaeb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(234, 234, 235);
`;